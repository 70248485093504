import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';

import TopBarContent from './TopBarContent';
import BottomBarContent from './BottomBarContent';
import SidebarContent from './SidebarContent';
import ChatContent from './ChatContent';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import Scrollbar from 'src/components/Scrollbar';

import {
  Box,
  styled,
  Divider,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material';

import * as io from 'socket.io-client';
import { config } from 'src/config';
import { useLocation } from 'react-router';

const socket = io.connect(config.FILe_URL, { transports: ['websocket'] });

const RootWrapper = styled(Box)(
  ({ theme }) => `
       height: calc(100vh - ${theme.header.height});
       display: flex;
`
);

const Sidebar = styled(Box)(
  ({ theme }) => `
        width: 300px;
        background: ${theme.colors.alpha.white[100]};
        border-right: ${theme.colors.alpha.black[10]} solid 1px;
`
);

const ChatWindow = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const ChatTopBar = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

function ApplicationsMessenger() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [chat, setChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const domNode = messagesEndRef.current;
    if (domNode) {
      domNode.scrollTo({
        top: domNode.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    // setTimeout(() => {
    //   scrollToBottom();
    // }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Messenger - Applications</title>
      </Helmet>
      <RootWrapper className="Mui-FixedWrapper">
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <Scrollbar>
            <SidebarContent setChat={setChat} chat={chat} />
          </Scrollbar>
        </DrawerWrapperMobile>
        <Sidebar
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
        >
          <Scrollbar>
            <SidebarContent setChat={setChat} chat={chat} />
          </Scrollbar>
        </Sidebar>
        {chat && (
          <ChatWindow>
            <ChatTopBar
              sx={{
                display: { xs: 'flex', lg: 'inline-block' }
              }}
            >
              <IconButtonToggle
                sx={{
                  display: { lg: 'none', xs: 'flex' },
                  mr: 2
                }}
                color="primary"
                onClick={handleDrawerToggle}
                size="small"
              >
                <MenuTwoToneIcon />
              </IconButtonToggle>
              <TopBarContent chat={chat} />
            </ChatTopBar>
            <Box
              flex={1}
              sx={{
                height: '100%',
                overflow: 'auto',
                scrollbarWidth: 'thin'
              }}
              ref={messagesEndRef}
            >
              {/* <Scrollbar> */}
              <ChatContent
                chat={chat}
                scrollToBottom={scrollToBottom}
                messages={messages}
                setMessages={setMessages}
                socket={socket}
              />
              {/* </Scrollbar> */}
            </Box>
            <BottomBarContent
              chat={chat}
              scrollToBottom={scrollToBottom}
              messages={messages}
              setMessages={setMessages}
              socket={socket}
            />
          </ChatWindow>
        )}
      </RootWrapper>
    </>
  );
}

export default ApplicationsMessenger;
